body {margin: 0; padding: 0; font-family: sans-serif; text-align: center;}
label {font-weight: bold;}
input {text-align: center}
a {
  display: block;
  cursor: pointer;
  color: blue !important;
}

a:hover {
  color: dodgerblue !important;
}

.footer {font-size: 0.8em; line-height: 1.4; text-align: left;}

.player-button {height: 110px; font-size: 3.5em !important;}

.player-cell {
  background-color: #ccc;
  border-radius: 10px;
  border: 1px solid #555;
  margin: 5px 0;
  padding-bottom: 5px;
}

.player-cell-controls {
  font-size: 0.5em;
  text-align: center;
  padding-top: 6px;
  cursor: pointer;
  padding-left: 0!important;
  padding-right: 0!important;
}

.status-header {
  font-size: 2em;
  border-radius: 10px;
  height: 50px;
}

.status-header h4 {
  padding-top: 10px;
}

.buzzed-in {
  background-color: #ff0;
}

.standby {
  background-color: green;
  color: white;
}

.disabled {
  background-color: red;
  color: white;
}

.board-player-center {
  padding-top: 14px;
}

.header-button {
  margin-top: 8px;
}

.header-button button {
  margin-right: 5px;
}

.choose-mode button {
  margin-bottom: 10px;
}

.invalid-code {
  color: red;
}